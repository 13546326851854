import React, { useContext, useEffect, useState, ReactNode } from 'react'

import { v4 as uuidV4 } from 'uuid'
import { AxiosError } from 'axios'
import { FormProvider, useForm } from 'react-hook-form'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTiktok,
  faThreads,
  faYoutube,
  faLinkedin,
  faInstagram,
  faXTwitter,
  faFacebook,
} from '@fortawesome/free-brands-svg-icons'

// Components
import Spinner from '../../atoms/Spinner'
import UploadImage from '../../atoms/UploadImage'
import RegisterInput from '../../atoms/RegisterInput'
import RegisterCheck from '../../atoms/RegisterCheck'
import { RegisterAuthor } from '../../../services/types'
import RegisterTextarea from '../../atoms/RegisterTextArea'
import RegisterCityPicker from '../../atoms/RegisterCityPicker'
import { authorName, normalizeText } from '../../../utils/utils'
import RegisterSimpleCheck from '../../atoms/RegisterSimpleCheck'
import RegisterAuthorPicker from '../../atoms/RegisterAuthorPicker'
import RegisterStringPicker from '../../atoms/RegisterStringPicker'

// Context
import ModalContext from '../../../context/Modal/Modal.context'
import RegisterContext from '../../../context/Register/Register.context'

// Services
import { postAuthor } from '../../../services/register'

// Style
import {
  grid,
  close,
  forms,
  formsLeft,
  formsRight,
  title,
  other,
  hidden,
  wrapper,
  spinner,
  subtitle,
  fullGrid,
  fullCell,
  socialIcon,
  descriptions,
  expandedCell,
  submitButton,
} from './style.module.scss'

interface Props {
  succsessModal: ReactNode
  active: boolean
  closeForm: () => void
}

const FormAuthor: React.FC<Props> = ({ succsessModal, active, closeForm }) => {
  const [authorId, setAuthorId] = useState<string>('')
  const [disable, setDisable] = useState(false)
  const [img, setImg] = useState<string | null>(null)
  const [birthplace, setBirthplace] = useState<string | null>(null)
  const [residence, setResidence] = useState<string | null>(null)
  const [posthumus, setPosthumus] = useState<boolean | null>(null)
  const [anonymize, setAnonymize] = useState<boolean | null>(null)
  const [author, setAuthor] = useState<string | null>(null)
  const [weeklyHours, setWeeklyHours] = useState<string | null>(null)
  const [finance, setFinance] = useState<string | null>(null)

  const { authors, updateAuthors } = useContext(RegisterContext)
  const { openModal } = useContext(ModalContext)

  const methods = useForm<RegisterAuthor>({
    mode: 'onTouched',
  })
  const {
    reset,
    register,
    getValues,
    clearErrors,
    formState: { isValid, errors },
  } = methods

  useEffect(() => {
    setAuthorId(uuidV4())
    clearErrors()

    // Load local data
    if (window) {
      const localAuthor = window.localStorage.getItem('author')
      if (localAuthor) {
        const author = JSON.parse(localAuthor)
        reset(author, { keepDefaultValues: true })
        setImg(author.image)
        setResidence(author.residence)
        setBirthplace(author.birthplace)
        setAuthor(author.recorder)
      }
    }
    // Auto-save
    const interval = setInterval(() => {
      if (window) {
        window.localStorage.setItem('author', JSON.stringify(getValues()))
      }
    }, 30000)

    //Clearing the interval
    return () => clearInterval(interval)
  }, [])

  const addAuthor = async (data: any) => {
    data.id = authorId
    if (data?.posthumous === false) {
      data.recorder = undefined
      data.recorderEmail = undefined
      data.relationship = undefined
    }
    for (let key of Object.keys(data)) {
      if (data[key] === '') {
        data[key] = undefined
      }
    }
    for (let key of Object.keys(data.opinion)) {
      if (data.opinion[key] === '') {
        data.opinion[key] = undefined
      }
    }
    try {
      await postAuthor(data)
      await updateAuthors()
      reset()
      setImg(null)
      setResidence(null)
      setBirthplace(null)
      setAuthor(null)
      openModal(succsessModal)
      setAuthorId(uuidV4())
      if (window) {
        window.localStorage.setItem('author', JSON.stringify(getValues()))
      }
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        const msg = error.response?.data.message
        openModal(<>Error: {msg}</>)
      }
    } finally {
      setDisable(false)
    }
  }

  const onSubmit = (data: any) => {
    setDisable(true)
    openModal(<Spinner className={spinner} />)
    addAuthor(data)
  }

  return (
    <div className={[wrapper, active ? undefined : hidden].join(' ')}>
      <div className={title}>
        Formulario de inscripción de perfil
        <FontAwesomeIcon icon={faClose} className={close} onClick={closeForm} />
      </div>
      <div className={descriptions}>
        <i>
          Este formulario le permitirá inscribir su perfil en la base de datos
          de cómic colombiano, como: autor, artista, editor, gestor cultural,
          investigador, educador, influencer, distribuidor, impresor, vendedor,
          también le permitirá inscribir el perfil de autores ya fallecidos que
          hagan parte de la historia del cómic colombiano.
        </i>
      </div>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') e.preventDefault()
          }}
        >
          <div className={subtitle}>Tratamiento de datos</div>
          <br />
          <div className={forms}>
            <RegisterCheck
              description="¿Autorizas hacer pública la información general de su perfil en las páginas web de comiccolombiano.com y Bogotá Cómics? "
              label="Autorización"
              register={register('authorize')}
              error={errors.authorize?.message}
              center
              justify
              defaultChecked
            />
            <RegisterCheck
              description="Marca esta casilla si no quiere que su nombre legal, fecha de nacimiento, ciudad de nacimiento y ciudad de residencia se muestren al público"
              label="Anonimizar"
              register={register('anonymize', {
                validate: (v) => {
                  setAnonymize(v)
                  return true
                },
              })}
              error={errors.anonymize?.message}
              center
              justify
            />
            <RegisterCheck
              description="Marca esta casilla si esta es una inscripción póstuma de un autor ya fallecido"
              label="Inscripción póstuma
"
              register={register('posthumous', {
                validate: (v) => {
                  setPosthumus(v)
                  return true
                },
              })}
              error={errors.posthumous?.message}
              center
              justify
            />
          </div>
          <br />
          {posthumus && (
            <>
              <div className={subtitle}> Inscripción póstuma</div>
              <br />
              <div className={forms}>
                <RegisterAuthorPicker
                  name="recorder"
                  external={author}
                  label="Quien realiza el registro"
                  description="Encargado del registro póstumo. Si no estás inscrito por favor realice su inscripción primero"
                  register={register('recorder', {
                    validate: (v) => {
                      setAuthor(v)
                      return true
                    },
                    required: 'Campo obligatorio',
                  })}
                  error={errors.recorder?.message}
                  required
                />
                <RegisterInput
                  label="Email de contacto"
                  description="Email de la persona que registra el perfil póstumo"
                  register={register('recorderEmail', {
                    required: 'Campo obligatorio',
                  })}
                  error={errors.recorderEmail?.message}
                  required
                />
                <RegisterInput
                  label="Parentesco"
                  description="Parentesco o relación con la persona fallecida"
                  register={register('relationship', {
                    required: 'Campo obligatorio',
                  })}
                  error={errors.relationship?.message}
                  required
                />
              </div>
            </>
          )}
          <br />
          <div className={forms}>
            <div className={formsLeft}>
              <div className={subtitle}>Foto o retrato</div>
              <UploadImage
                name={'image'}
                uuid={authorId}
                folder={'authors'}
                external={img}
              />
            </div>
            <div className={formsRight}>
              <div className={subtitle}>Información general</div>
              <div className={grid}>
                <RegisterInput
                  description="Nombre y apellido del artista"
                  label="Nombre completo"
                  required
                  register={register('fullName', {
                    required: 'Campo obligatorio',
                    validate: (v, o) => {
                      if (v && o.pseudonym) {
                        //Checking for duplicates
                        const duplicated = authors.find(
                          (a) =>
                            normalizeText(authorName(v, o.pseudonym)) ===
                            normalizeText(authorName(a.fullname, a.pseudonym))
                        )
                        if (duplicated)
                          return 'Ya existe un(a) artista con esta combinación de nombre y pseudónimo en nuestra base de datos'
                      }
                      return true
                    },
                  })}
                  error={errors.fullName?.message}
                />
                <RegisterInput
                  description="Seudónimo(s) que ha usado el artista"
                  label="Seudónimo"
                  register={register('pseudonym', {
                    required: anonymize
                      ? 'Campo obligatorio para registros anonimizados'
                      : undefined,
                    validate: (v, o) => {
                      if (v && o.pseudonym) {
                        //Checking for duplicates
                        const duplicated = authors.find(
                          (a) =>
                            normalizeText(authorName(o.fullName, v)) ===
                            normalizeText(authorName(a.fullname, a.pseudonym))
                        )
                        if (duplicated)
                          return 'Ya existe un(a) artista con esta combinación de nombre y seudónimo en nuestra base de datos'
                      }
                      return true
                    },
                  })}
                  required={!!anonymize}
                  error={errors.pseudonym?.message}
                />
                <RegisterInput
                  description="Pronombres con los que se identifica el artista"
                  label="Pronombres"
                  register={register('pronoun')}
                  error={errors.pronoun?.message}
                />
                <RegisterInput
                  description="Profesión(es) a las que se dedica el artista en el medio"
                  label="Profesión"
                  register={register('job')}
                  error={errors.job?.message}
                />
                <RegisterInput
                  description="Nivel de estudio más alto completado por el artista"
                  label="Estudios"
                  register={register('career')}
                  error={errors.career?.message}
                />
                <RegisterCityPicker
                  name="residence"
                  description="Ciudad en la que reside el artista"
                  label="Ciudad de residencia"
                  register={register('residence')}
                  error={errors.birthplace?.message}
                  external={residence}
                />
                <RegisterCityPicker
                  name="birthplace"
                  description="Ciudad en la que nació el artista"
                  label="Ciudad de nacimiento"
                  register={register('birthplace')}
                  error={errors.birthplace?.message}
                  external={birthplace}
                />
                <RegisterInput
                  description="Año en el que nació el artista"
                  label="Año de nacimiento"
                  type="number"
                  register={register('birthyear', {
                    min: {
                      value: 1800,
                      message: `La fecha debe estar entre 1800 y ${new Date().getFullYear()}`,
                    },
                    max: {
                      value: new Date().getFullYear(),
                      message: `La fecha debe estar entre 1800 y ${new Date().getFullYear()}`,
                    },
                  })}
                  error={errors.birthyear?.message}
                />
                {posthumus && (
                  <RegisterInput
                    description="Si el artista ya falleció, pon el año en que ocurrió el deceso"
                    type="number"
                    label="Año de fallecimiento"
                    register={register('deathyear', {
                      min: {
                        value: 1800,
                        message: `La fecha debe estar entre 1800 y ${new Date().getFullYear()}`,
                      },
                      maxLength: { value: 4, message: 'Hola' },
                      max: {
                        value: new Date().getFullYear(),
                        message: `La fecha debe estar entre 1800 y ${new Date().getFullYear()}`,
                      },
                    })}
                    error={errors.deathyear?.message}
                  />
                )}

                <div />
              </div>
            </div>
          </div>
          <div className={forms}>
            <div className={fullGrid}>
              <div className={subtitle}>Información de contacto</div>
              <RegisterInput
                description="E-mail de contacto"
                label="E-mail"
                register={register('email', {
                  pattern: {
                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    message: 'El e-mail no tiene el formato esperado',
                  },
                })}
                error={errors.email?.message}
              />
              <RegisterInput
                description="URL al perfil de Facebook (https://...)"
                label={
                  <span>
                    <FontAwesomeIcon icon={faFacebook} className={socialIcon} />
                    Facebook
                  </span>
                }
                register={register('facebook', {
                  pattern: {
                    value:
                      /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                    message:
                      'Por favor usar una URL segura (empiezan con https://..)',
                  },
                })}
                error={errors.facebook?.message}
              />
              <RegisterInput
                description="URL al perfil de Instagram (https://...)"
                label={
                  <span>
                    <FontAwesomeIcon
                      icon={faInstagram}
                      className={socialIcon}
                    />
                    Instagram
                  </span>
                }
                register={register('instagram', {
                  pattern: {
                    value:
                      /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                    message:
                      'Por favor usar una URL segura (empiezan con https://..)',
                  },
                })}
                error={errors.instagram?.message}
              />
              <RegisterInput
                description="URL al perfil de X (https://...)"
                label={
                  <span>
                    <FontAwesomeIcon icon={faXTwitter} className={socialIcon} />
                    X (Twitter)
                  </span>
                }
                register={register('twitter', {
                  pattern: {
                    value:
                      /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                    message:
                      'Por favor usar una URL segura (empiezan con https://..)',
                  },
                })}
                error={errors.twitter?.message}
              />
              <RegisterInput
                description="URL al perfil de YouTube (https://...)"
                label={
                  <span>
                    <FontAwesomeIcon icon={faYoutube} className={socialIcon} />
                    YouTube
                  </span>
                }
                register={register('youtube', {
                  pattern: {
                    value:
                      /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                    message:
                      'Por favor usar una URL segura (empiezan con https://..)',
                  },
                })}
                error={errors.youtube?.message}
              />
              <RegisterInput
                description="URL al perfil de TikTok (https://...)"
                label={
                  <span>
                    <FontAwesomeIcon icon={faTiktok} className={socialIcon} />
                    TikTok
                  </span>
                }
                register={register('tiktok', {
                  pattern: {
                    value:
                      /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                    message:
                      'Por favor usar una URL segura (empiezan con https://..)',
                  },
                })}
                error={errors.tiktok?.message}
              />
              <RegisterInput
                description="URL al perfil de Threads (https://...)"
                label={
                  <span>
                    <FontAwesomeIcon icon={faThreads} className={socialIcon} />
                    Threads
                  </span>
                }
                register={register('threads', {
                  pattern: {
                    value:
                      /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                    message:
                      'Por favor usar una URL segura (empiezan con https://..)',
                  },
                })}
                error={errors.threads?.message}
              />
              <RegisterInput
                description="URL al perfil de LinkedIn (https://...)"
                label={
                  <span>
                    <FontAwesomeIcon icon={faLinkedin} className={socialIcon} />
                    LinkedIn
                  </span>
                }
                register={register('linkedin', {
                  pattern: {
                    value:
                      /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                    message:
                      'Por favor usar una URL segura (empiezan con https://..)',
                  },
                })}
                error={errors.linkedin?.message}
              />
              <RegisterInput
                description="URL a la página de Patreon del artista (https://...)"
                label="Patreon"
                register={register('patreon', {
                  pattern: {
                    value:
                      /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                    message:
                      'Por favor usar una URL segura (empiezan con https://..)',
                  },
                })}
                error={errors.patreon?.message}
              />
              <RegisterInput
                description="URL a la página de Vaki del artista (https://...)"
                label="Vaki"
                register={register('vaki', {
                  pattern: {
                    value:
                      /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                    message:
                      'Por favor usar una URL segura (empiezan con https://..)',
                  },
                })}
                error={errors.vaki?.message}
              />
              <RegisterInput
                description="URL a la página del artista (https://...)"
                label="Página de internet"
                register={register('webpage', {
                  pattern: {
                    value:
                      /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                    message:
                      'Por favor usar una URL segura (empiezan con https://..)',
                  },
                })}
                error={errors.webpage?.message}
              />
              <div className={subtitle}>
                ¿En que area de trabajo te desempeñas en el sector cómic?
              </div>
              <div className={fullCell}>
                <RegisterSimpleCheck
                  label="Autor"
                  register={register('isAuthor')}
                />
                <RegisterSimpleCheck
                  label="Artista"
                  register={register('isArtist')}
                />
                <RegisterSimpleCheck
                  label="Editor"
                  register={register('isEditor')}
                />
                <RegisterSimpleCheck
                  label="Gestor cultural"
                  register={register('isCultural')}
                />
                <RegisterSimpleCheck
                  label="Investigador"
                  register={register('isInvestigator')}
                />
                <RegisterSimpleCheck
                  label="Educador"
                  register={register('isTeacher')}
                />
                <RegisterSimpleCheck
                  label="Influecer"
                  register={register('isInfluecer')}
                />
                <RegisterSimpleCheck
                  label="Distribuidor"
                  register={register('isDistributor')}
                />
                <RegisterSimpleCheck
                  label="Impresor"
                  register={register('isPrinter')}
                />
                <div className={other}>
                  <b>Otro:</b>
                  <RegisterInput register={register('isOther')} />
                </div>
              </div>
              <div className={expandedCell}>
                <div className={subtitle}>Biografía</div>
                <RegisterTextarea
                  description="Breve reseña de la vida del artista. Este es el campo que se mostrará al público. En máximo 500 palabras, en orden cronológico narre su propia historia referente a su recorrido en el sector cómic, teniendo en cuenta sus inicios, referentes, obras, logros y aportes más significativos. (Si es una inscripción de autor póstuma, incluye la fecha de su fallecimiento)."
                  register={register('description', {
                    minLength: {
                      value: 3,
                      message: 'Debe tener más de 3 caracteres',
                    },
                  })}
                  error={errors.description?.message}
                  center
                  justify
                />
              </div>
              <div className={expandedCell}>
                <div className={subtitle}>Trayectoria </div>
                <RegisterTextarea
                  description="Campo para destacar la trayectoria del artista. Este es el campo que se usará para las convocatorias y se mostrará a entidades como el IDARTES. En orden cronológico, enumera las publicaciones en las que ha trabajado: año, nombre de la publicación, rol que desempeño en ésta. (Puede mencionar las más significativas en su carrera)."
                  register={register('cv', {
                    minLength: {
                      value: 3,
                      message: 'Debe tener más de 3 caracteres',
                    },
                  })}
                  error={errors.cv?.message}
                  center
                  justify
                />
              </div>
              <div className={subtitle}>Opinón (Opcional)</div>
              <RegisterCheck
                description="¿Su actividad comercial principal es su trabajo en cómics?"
                label="Tiempo completo"
                register={register('opinion.fullTime')}
                error={errors.opinion?.fullTime?.message}
                center
                justify
              />
              <RegisterStringPicker
                name="opinion.weekHours"
                external={weeklyHours}
                label="Horas semanales"
                description="¿Cuántas horas a la semana dedicas a tus proyectos en el sector de los cómics? "
                register={register('opinion.weekHours', {
                  validate: (v) => {
                    setWeeklyHours(v)
                    return true
                  },
                })}
                error={errors.opinion?.weekHours?.message}
                options={[
                  'Menos de 10 horas',
                  '10 a 20 horas',
                  '20 a 30 horas',
                  '30 a 40 horas',
                  'Más de 40 horas',
                ]}
              />
              <RegisterStringPicker
                name="opinion.financeModel"
                external={finance}
                label="Modelo de financiación"
                description="Cual es su principal modelo de financiamiento? Elige el más importante"
                register={register('opinion.financeModel', {
                  validate: (v) => {
                    setFinance(v)
                    return true
                  },
                })}
                error={errors.opinion?.weekHours?.message}
                options={[
                  'Eventos de asistencia masiva',
                  'Pequeñas ferias y convenciones especializadas',
                  'Ventas en internet',
                  'Comisiones, trabajos por encargo',
                  'Puntos de venta',
                  'Sesión de derechos de publicación a sellos editoriales',
                  'Becas y auxilios estatales',
                  'Campañas de crowdfunding',
                  'Otro',
                ]}
              />
              {finance == 'Otro' && (
                <RegisterInput
                  description="Por favor solo agregar un modelo de financiación si no se encuentra en la lista"
                  label="¿Cuál"
                  register={register('opinion.financeModelOther')}
                  error={errors.webpage?.message}
                />
              )}
              <div className={subtitle}>
                ¿Cómo monetiza su trabajo en cómics?
              </div>
              <div className={descriptions}>
                <i>Elige todas las que apliquen</i>
              </div>
              <div className={fullCell}>
                <RegisterSimpleCheck
                  label="Comercializa obras y productos de cómics importados"
                  register={register('opinion.imports')}
                />
                <RegisterSimpleCheck
                  label="Trabaja con editoriales nacionales"
                  register={register('opinion.nationalPublishers')}
                />
                <RegisterSimpleCheck
                  label="Publica y vende sus propias obras"
                  register={register('opinion.ownWork')}
                />
                <RegisterSimpleCheck
                  label="Produce y comercializa mercancia propia derivada de sus obras"
                  register={register('opinion.ownMerch')}
                />
                <RegisterSimpleCheck
                  label="Adquiere los derechos de publicación de otros autores y comercializa sus obras"
                  register={register('opinion.otherAuthors')}
                />
                <RegisterSimpleCheck
                  label="Ha ganado becas para el apoyo a la cultura"
                  register={register('opinion.grants')}
                />
                <RegisterSimpleCheck
                  label="Organiza eventos, talleres y actividades relacionadas con el sector cómic"
                  register={register('opinion.event')}
                />
                <RegisterSimpleCheck
                  label="Promueve la enseñanza del cómic a través de academias o instituciónes educativas"
                  register={register('opinion.teaching')}
                />
                <div className={other}>
                  <b>Otro:</b>
                  <RegisterInput register={register('opinion.other')} />
                </div>
              </div>
              <div className={expandedCell}>
                <div className={subtitle}>Participación en eventos</div>
                <RegisterTextarea
                  description="¿Qué tan importante considera la participación en ferias editoriales y convenciones especializadas del sector? ¿En qué eventos participa regularmente?"
                  register={register('opinion.fairs')}
                  error={errors.opinion?.fairs?.message}
                  center
                  justify
                />
              </div>
              <div className={expandedCell}>
                <div className={subtitle}>Problematicas en eventos</div>
                <RegisterTextarea
                  description="Según su experiencia y trayectoría, ¿Cuáles son los principales problemas de los eventos que ofrece Bogotá en la actualidad para los artistas indipendientes? Considere costos de participación, montaje, producción, ventas, ubicación, espacios, público asistente, fechas etc."
                  register={register('opinion.problems')}
                  error={errors.opinion?.problems?.message}
                  center
                  justify
                />
              </div>
            </div>
          </div>
          <input
            type="submit"
            disabled={!isValid || disable}
            className={submitButton}
            value="Inscibir"
          />
        </form>
      </FormProvider>
    </div>
  )
}

export default FormAuthor
