import React, { useContext, useEffect, useState, ReactNode } from 'react'

import { v4 as uuidV4 } from 'uuid'
import { AxiosError } from 'axios'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faTrash } from '@fortawesome/free-solid-svg-icons'

// Components
import Spinner from '../../atoms/Spinner'
import UploadImage from '../../atoms/UploadImage'
import { normalizeText } from '../../../utils/utils'
import RegisterInput from '../../atoms/RegisterInput'
import RegisterCheck from '../../atoms/RegisterCheck'
import { RegisterForm } from '../../templates/Register'
import RegisterTextarea from '../../atoms/RegisterTextArea'
import RegisterAgePicker from '../../atoms/RegisterAgePicker'
import RegisterGenreGroup from '../../atoms/RegisterGenreGroup'
import RegisterCityPicker from '../../atoms/RegisterCityPicker'
import RegisterTypePicker from '../../atoms/RegisterTypePicker'
import RegisterStringPicker from '../../atoms/RegisterStringPicker'
import RegisterDemSexPicker from '../../atoms/RegisterDemSexPicker'
import RegisterAuthorPicker from '../../atoms/RegisterAuthorPicker'
import RegisterDemAgePicker from '../../atoms/RegisterDemAgePicker'
import RegisterStatusPicker from '../../atoms/RegisterStatusPicker'
import RegisterCountryPicker from '../../atoms/RegisterCountryPicker'
import RegisterWarningPicker from '../../atoms/RegisterWarningPicker'
import RegisterPublisherPicker from '../../atoms/RegisterPublisherPicker'
import RegisterDistributionPicker from '../../atoms/RegisterDistributionPicker'

// Types
import { authorRoles, RegisterSeries } from '../../../services/types'

// Context
import ModalContext from '../../../context/Modal/Modal.context'
import RegisterContext from '../../../context/Register/Register.context'

// Services
import { postSeries } from '../../../services/register'

// Style
import {
  grid,
  team,
  close,
  trash,
  forms,
  formsLeft,
  formsRight,
  border,
  title,
  hidden,
  header,
  images,
  imagesContainer,
  button,
  buttons,
  wrapper,
  spinner,
  subtitle,
  noMobile,
  chapterGrid,
  descriptions,
  expandedCell,
  submitButton,
} from './style.module.scss'

interface Props {
  succsessModal: ReactNode
  active: boolean
  closeForm: () => void
  openForm: (form: RegisterForm) => void
}

const FormSeries: React.FC<Props> = ({
  succsessModal,
  active,
  closeForm,
  openForm,
}) => {
  const [seriesId, setSeriesId] = useState<string>('')
  const [disable, setDisable] = useState(false)
  const [img, setImg] = useState<string | null>(null)
  const [country, setCountry] = useState<string | null>(null)
  const [status, setStatus] = useState<number | null>(null)

  const [genresArr, setGenresArr] = useState<string[]>([])

  const [comicUUID, setComicUUID] = useState([uuidV4(), uuidV4()])
  const [roles, setRoles] = useState<string[]>([])
  const [authors, setAuthors] = useState<string[]>([])

  const [city, setCity] = useState<string | null>(null)
  const [cover, setCover] = useState<string | null>(null)
  const [image, setImage] = useState<string | null>(null)
  const [demAge, setDemAge] = useState<string | null>(null)
  const [demSex, setDemSex] = useState<string | null>(null)
  const [typePub, setTypePub] = useState<string | null>(null)
  const [ageRange, setAgeRange] = useState<number | null>(null)
  const [publisher, setPublisher] = useState<string | null>(null)
  const [distribution, setDistribution] = useState<number | null>(null)

  const [sex, setSex] = useState<string | null>(null)
  const [nude, setNude] = useState<string | null>(null)
  const [blood, setBlood] = useState<string | null>(null)
  const [language, setLanguage] = useState<string | null>(null)
  const [violence, setViolence] = useState<string | null>(null)
  const [substances, setSubstances] = useState<string | null>(null)

  const { series, updateSeries, types } = useContext(RegisterContext)
  const { openModal } = useContext(ModalContext)

  const methods = useForm<RegisterSeries>({
    mode: 'onTouched',
  })
  const {
    reset,
    control,
    register,
    getValues,
    clearErrors,
    formState: { isValid, errors },
  } = methods

  const {
    fields: tFields,
    remove: tRemove,
    append: tAppend,
  } = useFieldArray({
    control,
    name: 'team',
  })

  useEffect(() => {
    setSeriesId(uuidV4())
    clearErrors()

    // Load local data
    if (window) {
      const localSeries = window.localStorage.getItem('series')
      if (localSeries) {
        const series = JSON.parse(localSeries)
        reset(series, { keepDefaultValues: true })

        setImg(series.series.cover)
        setStatus(series.series.status)
        setGenresArr([
          series.genres[0].genre,
          series.genres[1].genre,
          series.genres[2].genre,
        ])

        setCity(series.publications[0].city)
        setImage(series.publications[0].image)
        setAgeRange(series.publications[0].age)
        setDemSex(series.publications[0].demSex)
        setDemAge(series.publications[0].demAge)
        setCountry(series.publications[0].country)
        setPublisher(series.publications[0].publisher)
        setDistribution(series.publications[0].distribution)
        setSex(series.publications[0].sexWarn)
        setNude(series.publications[0].nudeWarn)
        setBlood(series.publications[0].bloodWarn)
        setViolence(series.publications[0].violenceWarn)
        setLanguage(series.publications[0].languageWarn)
        setSubstances(series.publications[0].substancesWarn)

        setRoles(series.team?.map((a: any) => a?.role) ?? [])
        setAuthors(series.team?.map((a: any) => a?.author) ?? [])
      }
    }
    // Auto-save
    const interval = setInterval(() => {
      if (window) {
        window.localStorage.setItem('series', JSON.stringify(getValues()))
      }
    }, 30000)

    //Clearing the interval
    return () => clearInterval(interval)
  }, [])

  const addSeries = async (data: any) => {
    data.series.id = seriesId
    const pubId = uuidV4()
    for (let key of Object.keys(data.series)) {
      if (data.series[key] === '') {
        data.series[key] = undefined
      }
    }
    data.publications = data.publications.map((p: any) => {
      for (let key of Object.keys(p)) {
        if (p[key] === '') {
          p[key] = undefined
        }
      }
      p.type = p.typePublication
      p.id = p.chapterId
      p.series = seriesId
      p.id = pubId
      return p
    })

    data.genres = data.genres.map((g: any) => ({
      id: uuidV4(),
      series: seriesId,
      genre: g.genre,
    }))

    data.team = data.team.map((t: any) => ({ ...t, publication: pubId }))
    
    try {
      console.log(data)
      await postSeries(data)
      await updateSeries()
      reset()
      setImg(null)
      setCity(null)
      setImage(null)
      setCover(null)
      setStatus(null)
      setDemAge(null)
      setDemSex(null)
      setCountry(null)
      setTypePub(null)
      setAgeRange(null)
      setPublisher(null)
      setDistribution(null)
      setAuthors([])
      setGenresArr([])
      openModal(succsessModal)
      setSeriesId(uuidV4())
      setComicUUID([uuidV4(), uuidV4()])
      if (window) {
        window.localStorage.setItem('series', JSON.stringify(getValues()))
      }
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        const msg = error.response?.data.message
        openModal(<>Error: {msg}</>)
      }
    } finally {
      setDisable(false)
    }
  }

  const onSubmit = (data: any) => {
    setDisable(true)
    openModal(<Spinner className={spinner} />)
    addSeries(data)
  }

  return (
    <div className={[wrapper, active ? undefined : hidden].join(' ')}>
      <div className={title}>
        Formulario de inscripción de series
        <FontAwesomeIcon icon={faClose} className={close} onClick={closeForm} />
      </div>
      <div className={descriptions}>
        <i>
          Este formulario te permitirá inscribir una nueva serie, es decir un
          comic que tenga más de un capítulo. Si tu cómic tiene un solo capítulo
          usa la opción de <b>inscribir un One-shot.</b> Recuerda que cuanta más
          información nos proporciones, mejor será la impresión que los usuarios
          tendrán de la serie y nos permitirá realizar mejores análisis de la
          situación del cómic colombiano.
        </i>
      </div>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') e.preventDefault()
          }}
        >
          <div className={forms}>
            <div className={formsLeft}>
              <div className={formsLeft}>
                <div className={subtitle}>Portada de la serie</div>
                <UploadImage
                  name={'series.cover'}
                  uuid={seriesId}
                  folder={'series'}
                  external={img}
                />
              </div>
            </div>
            <div className={formsRight}>
              <div className={subtitle}>Información de la serie</div>
              <div className={grid}>
                <RegisterInput
                  required
                  description="Título de la serie"
                  label="Título"
                  register={register('series.title', {
                    required: { value: true, message: 'Campo requerido' },
                    validate: (v) => {
                      if (v) {
                        //Checking for duplicates
                        const duplicated = series.find(
                          (s) => normalizeText(v) === normalizeText(s.title)
                        )
                        if (duplicated)
                          return 'Ya existe una serie con este título en nuestra base de datos'
                      }
                      return true
                    },
                  })}
                  error={errors?.series?.title?.message}
                />
                <RegisterStatusPicker
                  name="series.status"
                  description="Estado de la serie. Finalizada, En progreso, etc..."
                  label="Estado de publicación"
                  register={register('series.status')}
                  error={errors.series?.status?.message}
                  external={status}
                />
                <RegisterCheck
                  description="¿Autorizas hacer pública la información en las páginas web de comiccolombiano.com y Bogotá Cómics? "
                  label="Autorización"
                  register={register('series.authorize')}
                  error={errors.series?.authorize?.message}
                  center
                  justify
                  defaultChecked
                />
                <div className={expandedCell}>
                  <RegisterGenreGroup
                    required
                    names={[
                      'genres.0.genre',
                      'genres.1.genre',
                      'genres.2.genre',
                    ]}
                    description="Elige hasta 3 generos para el cómic."
                    label="Géneros"
                    registers={[
                      register('genres.0.genre'),
                      register('genres.1.genre'),
                      register('genres.2.genre'),
                    ]}
                    error={errors.genres?.message}
                    external={genresArr}
                    setExternal={setGenresArr}
                  />
                </div>
                <div className={expandedCell}>
                  <div className={subtitle}>Sinopsis</div>
                  <RegisterTextarea
                    description="Breve sinopsis de la serie. Esta es la oportunidad para presentar tu trabajo al público y captar su interés. Asegúrate de transmitir tu pasión y esencia artística de la mejor manera posible, destacando los momentos clave de tu historia y lo que hace único tu enfoque creativo."
                    register={register('series.description', {
                      minLength: {
                        value: 3,
                        message: 'Debe tener mas de 3 caracteres',
                      },
                    })}
                    error={errors.series?.description?.message}
                    center
                    justify
                  />
                </div>
                <div className={subtitle}>Links importantes</div>
                <RegisterInput
                  description="URL a la página de la serie (https://...)"
                  label="Link de lectura"
                  register={register('series.link', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.series?.link?.message}
                />
                <RegisterInput
                  description="URL a la tienda web de la serie (https://...)"
                  label="Tienda virtual"
                  register={register('series.shop', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.series?.shop?.message}
                />
                <RegisterInput
                  description="URL a una vista previa de la serie (https://...)"
                  label="Vista previa"
                  register={register('series.preview', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.series?.preview?.message}
                />
                <RegisterInput
                  description="URL a la página de Patreon de la serie (https://...)"
                  label="Patreon"
                  register={register('series.patreon', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.series?.patreon?.message}
                />
                <RegisterInput
                  description="URL a la página de Vaki de la serie (https://...)"
                  label="Vaki"
                  register={register('series.vaki', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.series?.vaki?.message}
                />
              </div>
            </div>
            <div className={[chapterGrid, border].join(' ')}>
              <div className={subtitle}>Primer Capitulo</div>
              <RegisterInput
                required
                description="Título de la publicación"
                label="Título"
                register={register('publications.0.name')}
                error={
                  errors.publications && errors.publications[0]?.name?.message
                }
              />
              <RegisterInput
                required
                type="number"
                description="Número de la publicación"
                label="Número"
                register={register('publications.0.number', {
                  required: { value: true, message: 'Campo requerido' },
                })}
                error={
                  errors.publications && errors.publications[0]?.number?.message
                }
              />
              <RegisterTypePicker
                required
                name="publications.0.typePublication"
                description="Tipo de la publicación"
                label="Tipo de capítulo"
                register={register('publications.0.typePublication', {
                  validate: (v) => {
                    setTypePub(v ?? '')
                    return true
                  },
                })}
                error={
                  errors.publications &&
                  errors.publications[0]?.typePublication?.message
                }
                external={typePub}
              />
              <RegisterPublisherPicker
                name="publications.0.publisher"
                description="Editorial que financió la publicación. Dejar vació si es independiente"
                label="Editorial"
                register={register('publications.0.publisher')}
                error={
                  errors.publications &&
                  errors.publications[0]?.typePublication?.message
                }
                external={publisher}
              />
              <RegisterDistributionPicker
                name="publications.0.distribution"
                description="Impreso, digital o mixto"
                label="Tipo de distribución"
                register={register('publications.0.distribution', {
                  validate: (v) => {
                    setDistribution(v)
                    return true
                  },
                })}
                error={
                  errors.publications &&
                  errors.publications[0]?.distribution?.message
                }
                external={distribution}
              />
              <RegisterInput
                type="number"
                description="Año en el que se realizó la primera publicación"
                label="Año de publicación"
                register={register('publications.0.year', {
                  min: {
                    value: 1800,
                    message: `La fecha debe estar entre 1800 y ${new Date().getFullYear()}`,
                  },
                  max: {
                    value: new Date().getFullYear(),
                    message: `La fecha debe estar entre 1800 y ${new Date().getFullYear()}`,
                  },
                })}
                error={
                  errors.publications && errors.publications[0]?.year?.message
                }
              />
              <RegisterCountryPicker
                name="publications.0.country"
                description="País en el que que se publicó por primera vez. Si es un comic internacional dejar vació"
                label="País de publicación"
                register={register('publications.0.country', {
                  validate: (v) => {
                    setCountry(v)
                    return true
                  },
                })}
                error={
                  errors.publications &&
                  errors.publications[0]?.country?.message
                }
                external={country}
              />
              {country === '19124f6c-3e45-11ef-83f3-b8ca3aeea906' && (
                <RegisterCityPicker
                  name="publications.0.city"
                  description="Ciudad de Colombia en la que se publicó por primera vez. Si es un comic internacional dejar vació"
                  label="Ciudad de publicación"
                  register={register('publications.0.city', {
                    validate: (v) => {
                      setCity(v)
                      return true
                    },
                  })}
                  error={
                    errors.publications && errors.publications[0]?.city?.message
                  }
                  external={city}
                />
              )}
              <RegisterInput
                description="ISBN de la primera publicación que tenga"
                label="ISBN/ISSN"
                register={register('publications.0.isbn')}
                error={
                  errors.publications && errors.publications[0]?.isbn?.message
                }
              />
              {(distribution === 3 || distribution === 4) && (
                <>
                  <RegisterInput
                    type="number"
                    description="Copias impresas en total"
                    label="Copias impresas"
                    register={register('publications.0.copies')}
                    error={
                      errors.publications &&
                      errors.publications[0]?.copies?.message
                    }
                  />
                  <RegisterInput
                    type="number"
                    description="Cuantas ediciones tiene la publicación"
                    label="Numero de ediciones"
                    register={register('publications.0.reditions')}
                    error={
                      errors.publications &&
                      errors.publications[0]?.reditions?.message
                    }
                  />
                </>
              )}
              {(typePub === '21568f41-41f9-11ef-9dfe-b8ca3aeea906' ||
                typePub === '21569aab-41f9-11ef-9dfe-b8ca3aeea906') && (
                <>
                  {typePub === '21568f41-41f9-11ef-9dfe-b8ca3aeea906' && (
                    <RegisterInput
                      description="Que capítulos incluye tu volumen compilatorio"
                      label="Capítulos"
                      register={register('publications.0.includes')}
                      error={
                        errors.publications &&
                        errors.publications[0]?.includes?.message
                      }
                    />
                  )}
                  <RegisterInput
                    description="Elementos adicionales de tu volumen compilatorio o edición especial"
                    label="Extras"
                    register={register('publications.0.extras')}
                    error={
                      errors.publications &&
                      errors.publications[0]?.extras?.message
                    }
                  />
                </>
              )}
              <div className={expandedCell}>
                <i>Si no encuntras la editorial puedes inscribirla aqui</i>
                <div
                  className={button}
                  onClick={() => {
                    openForm('Publisher')
                  }}
                >
                  Inscribir editorial
                </div>
              </div>
              <div className={imagesContainer}>
                <div className={images}>
                  <div className={subtitle}>Portada</div>
                  <UploadImage
                    name="publications.0.cover"
                    uuid={comicUUID[0]}
                    folder="publications"
                    external={cover}
                  />
                </div>
                <div className={images}>
                  <div className={subtitle}>Página Interna</div>
                  <UploadImage
                    name="publications.0.image"
                    uuid={comicUUID[1]}
                    folder="publications"
                    external={image}
                  />
                </div>
              </div>
              <div className={subtitle}>Sinopsis del Capítulo</div>
              <div className={expandedCell}>
                <RegisterTextarea
                  description="Breve sinopsis del capítulo. Esta es la oportunidad para presentar tu trabajo al público y captar su interés. Asegúrate de transmitir tu pasión y esencia artística de la mejor manera posible, destacando los momentos clave de tu historia y lo que hace único tu enfoque creativo."
                  register={register('publications.0.description', {
                    minLength: {
                      value: 3,
                      message: 'Debe tener mas de 3 caracteres',
                    },
                  })}
                  error={
                    errors.publications &&
                    errors.publications[0]?.description?.message
                  }
                  center
                  justify
                />
              </div>
              <div className={subtitle}>Equipo creativo</div>
              <div className={expandedCell}>
                <div className={team}>
                  <div className={header}>Autor</div>
                  <div className={header}>Rol</div>
                  <div />
                  {tFields.map((a, index) => (
                    <>
                      <RegisterAuthorPicker
                        key={`team.${index}.author`}
                        register={register(`team.${index}.author`, {
                          validate: (v) => {
                            authors[index] = v ?? ''
                            setAuthors([...authors])
                            return true
                          },
                        })}
                        name={`team.${index}.author`}
                        external={authors[index]}
                      />
                      <RegisterStringPicker
                        key={`team.${index}.role`}
                        options={authorRoles.map((o) => o)}
                        register={register(`team.${index}.role`, {
                          validate: (v) => {
                            roles[index] = v ?? ''
                            setRoles([...roles])
                            return true
                          },
                        })}
                        name={`team.${index}.role`}
                        external={roles[index]}
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        className={trash}
                        onClick={() => {
                          tRemove(index)
                          authors.splice(index, 1)
                          roles.splice(index, 1)
                          setAuthors([...authors])
                          setRoles([...roles])
                        }}
                      />
                    </>
                  ))}
                </div>
                <div className={buttons}>
                  <div
                    className={button}
                    onClick={() => {
                      const id = uuidV4()
                      setAuthors([...authors, ''])
                      setRoles([...roles, ''])
                      tAppend({
                        id,
                        author: '',
                        publication: '',
                        role: '',
                      })
                    }}
                  >
                    Agregar autor
                  </div>
                  <div
                    className={button}
                    onClick={() => {
                      openForm('Author')
                    }}
                  >
                    Inscribir autor
                  </div>
                </div>
              </div>
              <div className={subtitle}>Links de acceso</div>
              <div className={noMobile} />
              <RegisterInput
                description="URL de una página donde se pueda leer directamente la publicación (https://...)"
                label="Link de lectura"
                register={register('publications.0.link', {
                  pattern: {
                    value:
                      /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                    message:
                      'Favor usar una URL segura (empiezan con https://..)',
                  },
                })}
                error={
                  errors.publications && errors.publications[0]?.link?.message
                }
              />
              <RegisterInput
                description="URL de una página donde se pueda comprar directamente la publicación (https://...)"
                label="Link de venta"
                register={register('publications.0.shop', {
                  pattern: {
                    value:
                      /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                    message:
                      'Favor usar una URL segura (empiezan con https://..)',
                  },
                })}
                error={
                  errors.publications && errors.publications[0]?.shop?.message
                }
              />
              <div className={subtitle}>Público objetivo</div>
              <div className={noMobile} />
              <RegisterDemAgePicker
                name="publications.0.demAge"
                description="Rango de edad de tu público objetivo"
                label="Edad"
                register={register('publications.0.demAge')}
                error={
                  errors.publications && errors.publications[0]?.demAge?.message
                }
                external={demAge}
              />
              <RegisterDemSexPicker
                name="publications.0.demSex"
                description="Género del público objetivo"
                label="Género"
                register={register('publications.0.demSex')}
                error={
                  errors.publications && errors.publications[0]?.demSex?.message
                }
                external={demSex}
              />
              <div />
              <div className={subtitle}>Advertencias</div>
              <RegisterAgePicker
                name="publications.0.age"
                description="Edad mínima sugerida, no es necesariamente la misma del público objetivo"
                label="Edad mínima"
                register={register('publications.0.age')}
                error={
                  errors.publications && errors.publications[0]?.age?.message
                }
                external={ageRange}
              />
              <RegisterWarningPicker
                type="language"
                name="publications.0.languageWarn"
                description="Elige el tipo de lenguaje fuerte que tiene tu cómic"
                label="Lenguaje fuerte"
                register={register('publications.0.languageWarn', {
                  validate: (v) => {
                    setLanguage(v)
                    return true
                  },
                })}
                error={
                  errors.publications &&
                  errors.publications[0]?.languageWarn?.message
                }
                external={language}
              />
              <RegisterWarningPicker
                type="violence"
                name="publications.0.violenceWarn"
                description="Elige el tipo de violencia que tiene tu cómic"
                label="Violencia"
                register={register('publications.0.violenceWarn', {
                  validate: (v) => {
                    setViolence(v)
                    return true
                  },
                })}
                error={
                  errors.publications &&
                  errors.publications[0]?.violenceWarn?.message
                }
                external={violence}
              />
              <RegisterWarningPicker
                type="substances"
                name="publications.0.substancesWarn"
                description="Elige como tu cómic muestra el uso de sustancias"
                label="Sustancias"
                register={register('publications.0.substancesWarn', {
                  validate: (v) => {
                    setSubstances(v)
                    return true
                  },
                })}
                error={
                  errors.publications &&
                  errors.publications[0]?.substancesWarn?.message
                }
                external={substances}
              />
              <RegisterWarningPicker
                type="blood"
                name="publications.0.bloodWarn"
                description="Elige como tu cómic muestra la sangre"
                label="Sangre"
                register={register('publications.0.bloodWarn', {
                  validate: (v) => {
                    setBlood(v)
                    return true
                  },
                })}
                error={
                  errors.publications &&
                  errors.publications[0]?.bloodWarn?.message
                }
                external={blood}
              />
              <RegisterWarningPicker
                type="nude"
                name="publications.0.nudeWarn"
                description="Elige como tu cómic muestra desnudos"
                label="Desnudos"
                register={register('publications.0.nudeWarn', {
                  validate: (v) => {
                    setNude(v)
                    return true
                  },
                })}
                error={
                  errors.publications &&
                  errors.publications[0]?.nudeWarn?.message
                }
                external={nude}
              />
              <RegisterWarningPicker
                type="sex"
                name="publications.0.sexWarn"
                description="Elige como tu cómic muestra actos sexuales"
                label="Sexo"
                register={register('publications.0.sexWarn', {
                  validate: (v) => {
                    setSex(v)
                    return true
                  },
                })}
                error={
                  errors.publications &&
                  errors.publications[0]?.sexWarn?.message
                }
                external={sex}
              />
            </div>
            <div className={formsRight}></div>
          </div>
          <input
            type="submit"
            disabled={!isValid || disable}
            className={submitButton}
            value="Inscibir"
          />
        </form>
      </FormProvider>
    </div>
  )

  /* return (
    <div className={[wrapper, active ? undefined : hidden].join(' ')}>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') e.preventDefault()
          }}
        >
          <div className={forms}>
            <div className={formsLeft}>
            </div>
            
            <div>
              <div className={[chapterGrid, border].join(' ')}>
                <div className={subtitle}>Equipo creativo</div>
                <div className={expandedCell}>
                  <div className={team}>
                    <div className={header}>Autor</div>
                    <div className={header}>Rol</div>
                    <div />
                    {tFields.map((a, index) => (
                      <>
                        <RegisterAuthorPicker
                          key={`team.${index}.author`}
                          register={register(`team.${index}.author`, {
                            validate: (v) => {
                              authors[index] = v ?? ''
                              setAuthors([...authors])
                              return true
                            },
                          })}
                          name={`team.${index}.author`}
                          external={authors[index]}
                        />
                        <RegisterStringPicker
                          key={`team.${index}.role`}
                          options={authorRoles.map((o) => o)}
                          register={register(`team.${index}.role`, {
                            validate: (v) => {
                              roles[index] = v ?? ''
                              setRoles([...roles])
                              return true
                            },
                          })}
                          name={`team.${index}.role`}
                          external={roles[index]}
                        />
                        <FontAwesomeIcon
                          icon={faTrash}
                          className={trash}
                          onClick={() => {
                            tRemove(index)
                            authors.splice(index, 1)
                            roles.splice(index, 1)
                            setAuthors([...authors])
                            setRoles([...roles])
                          }}
                        />
                      </>
                    ))}
                  </div>
                  <div className={buttons}>
                    <div
                      className={button}
                      onClick={() => {
                        const id = uuidV4()
                        setAuthors([...authors, ''])
                        setRoles([...roles, ''])
                        tAppend({
                          id,
                          author: '',
                          publication: '',
                          role: '',
                        })
                      }}
                    >
                      Agregar autor
                    </div>
                    <div
                      className={button}
                      onClick={() => {
                        openForm('Author')
                      }}
                    >
                      Inscribir autor
                    </div>
                  </div>
                </div>
                <div className={subtitle}>Links de acceso</div>
                <div className={noMobile} />
                <RegisterInput
                  description="URL de una página donde se pueda leer directamente la publicación (https://...)"
                  label="Link de lectura"
                  register={register('series.preview', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={
                    errors.publications && errors.publications[0]?.link?.message
                  }
                />
                <RegisterInput
                  description="URL de una página donde se pueda comprar directamente la publicación (https://...)"
                  label="Link de venta"
                  register={register('publications.0.shop', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={
                    errors.publications && errors.publications[0]?.shop?.message
                  }
                />
                
              </div>
            </div>
          </div>
          <input
            type="submit"
            disabled={!isValid || disable}
            className={submitButton}
            value="Inscibir"
          />
        </form>
      </FormProvider>
    </div>
  ) */
}

export default FormSeries
