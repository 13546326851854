export type RegisterVariants = '' | 'graphic_novel' | 'volume'

export type City = {
  id: string
  name: string
  departmentId: string
}

export type Department = {
  id: string
  name: string
  countyId: string
}

export type Country = {
  id: string
  name: string
  code: string
}

export type DemAge = {
  id: string
  name: string
  start: number
  end: number
}

export type DemSex = {
  id: string
  name: string
}

export type Status = {
  id: number
  name: string
}

export type Distribution = {
  id: number
  name: string
}

export type PublicationType = {
  id: string
  name: string
}

export type PublicationTeam = {
  id: string
  author: string
  publication: string
  role: string
}

export type SeriesGenre = {
  id: string
  series: string
  genre: string
}

export type Genre = {
  id: string
  name: string
  description: string
}

export type AgeRanges = {
  id: number
  name: string
  code: string
}

export type WarningType =
  | 'violence'
  | 'nude'
  | 'sex'
  | 'substances'
  | 'blood'
  | 'language'

export type Warnings = {
  id: string
  name: string
  type: string
}

export type Series = {
  id: string
  title: string
  cover: string | null
  description: string | null
  status: number | null
  country: string | null
  preview: string | null
  link: string | null
  shop: string | null
  vaki: string | null
  patreon: string | null
  tags: string | null
}

export type SeriesMinified = {
  id: string
  title: string
}
export type Author = {
  id: string
  fullname: string | null
  pseudonym: string | null
  pronoun: string | null
  image: string | null
  job: string | null
  career: string | null
  description: string | null
  birthyear: number | null
  deathyear: number | null
  facebook: string | null
  instagram: string | null
  twitter: string | null
  youtube: string | null
  tiktok: string | null
  threads: string | null
  linkedin: string | null
  webpage: string | null
  birthplace: string | null
  residence: string | null
}

export type AuthorMinified = {
  id: string
  fullname: string
  pseudonym: string
}

export type Publisher = {
  id: string
  name: string
  logo: string | null
  city: string | null
  country: string | null
  startYear: number | null
  endYear: number | null
  description: string | null
  email: string | null
  webpage: string | null
  facebook: string | null
  instagram: string | null
  twitter: string | null
  youtube: string | null
  tiktok: string | null
  threads: string | null
  linkedin: string | null
  vaki: string | null
  patreon: string | null
}

export type PublisherMinified = {
  id: string
  name: string
}

export type Publications = {
  id: string
  name: string
  series: string
  type: string | null
  number: number | null
  cover: string | null
  image: string | null
  year: number | null
  publisher: string | null
  age: number | null
  city: string | null
  isbn: string | null
  distribution: number | null
  copies: number | null
  reditions: number | null
  description: string | null
  includes: string | null
  extras: string | null
  link: string | null
  shop: string | null
  emailShop: string | null
  nameTags: string | null
  authorTags: string | null
  otherTags: string | null
  languageWarn: string | null
  violenceWarn: string | null
  substancesWarn: string | null
  bloodWarn: string | null
  nudeWarn: string | null
  sexWarn: string | null
  demAge: string | null
  demSex: string | null
}
export type PublicationsMinified = {
  id: string
  name: string
  series: string
}

export type RegisterAuthor = {
  id: string
  anonymize: boolean | null
  posthumous: boolean | null
  authorize: boolean | null
  recorder: string | null
  recorderEmail: string | null
  relationship: string | null
  fullName: string | null
  pseudonym: string | null
  pronoun: string | null
  image: string | null
  job: string | null
  career: string | null
  birthyear: string | null
  deathyear: string | null
  birthplace: string | null
  residence: string | null
  description: string | null
  cv: string | null
  email: string | null
  facebook: string | null
  instagram: string | null
  twitter: string | null
  youtube: string | null
  tiktok: string | null
  threads: string | null
  linkedin: string | null
  webpage: string | null
  vaki: string | null
  patreon: string | null
  isAuthor: boolean | null
  isArtist: boolean | null
  isEditor: boolean | null
  isCultural: boolean | null
  isInvestigator: boolean | null
  isTeacher: boolean | null
  isInfluecer: boolean | null
  isDistributor: boolean | null
  isPrinter: boolean | null
  isOther: string | null
  opinion: Opinion
}

export type Opinion = {
  fullTime: boolean | null
  weekHours: string | null
  financeModel: string | null
  financeModelOther: string | null
  fairs: string | null
  imports: boolean | null
  nationalPublishers: boolean | null
  internationalPublishers: boolean | null
  ownWork: boolean | null
  ownMerch: boolean | null
  otherAuthors: boolean | null
  grants: boolean | null
  event: boolean | null
  teaching: boolean | null
  other: string | null
  problems: string | null
}

export type RegisterPublisher = {
  id: string
  name: string
  description: string | null
  city: string | null
  country: string | null
  startYear: string | null
  endYear: string | null
  logo: string | null
  email: string | null
  webpage: string | null
  facebook: string | null
  instagram: string | null
  twitter: string | null
  youtube: string | null
  tiktok: string | null
  threads: string | null
  linkedin: string | null
  vaki: string | null
  patreon: string | null
  colombian?: boolean
  authorize: boolean | null
}

type RegisterChapter = {
  id: string
  authorize: boolean | null
  chapterId?: string
  name: string
  series: string
  type: string
  typePublication?: string
  number: number
  cover: string | null
  image: string | null
  year: number | null
  publisher: string | null
  age: number | null
  country: string | null
  city: string | null
  isbn: string | null
  distribution: number | null
  copies: number | null
  reditions: number | null
  description: string | null
  includes: string | null
  extras: string | null
  link: string | null
  shop: string | null
  emailShop: string | null
  languageWarn: string | null
  violenceWarn: string | null
  substancesWarn: string | null
  bloodWarn: string | null
  nudeWarn: string | null
  sexWarn: string | null
  demAge: string | null
  demSex: string | null
}

export type RegisterSeries = {
  series?: {
    id: string
    authorize: boolean | null
    title: string
    cover: string | null
    description: string | null
    status: number | null
    preview: string | null
    link: string | null
    shop: string | null
    vaki: string | null
    patreon: string | null
  }
  publications: RegisterChapter[]
  team: RegisterTeam[]
  genres: { id: string; series: string; genre: string }[]
  auxChapter?: RegisterChapter
  auxAuthors?: RegisterTeam[]
}

export const authorRoles = [
  '',
  'Autor',
  'Escritor',
  'Ilustrador',
  'Entintador',
  'Colorista',
  'Ilustrador - Portada',
  'Entintador - Portada',
  'Colorista - Portada',
  'Letrista',
  'Editor',
  'Director Editorial',
  'Maquetador',
  'Diseñador',
  'Asistente',
  'Asistente Editorial',
  'Inversor',
  'Revisor de Estilo',
  'Adaptador',
  'Traductor',
  'Fotografo',
  'Narrador',
  'Prologuista',
] as const

type AuthorRoles = (typeof authorRoles)[number]

type RegisterTeam = {
  id: string
  publication: string
  author: string
  role: AuthorRoles | null
}

export type RegisterSofa2024 = {
  id: string
  author: string
  series: string
  publisher?: string
  whatsapp: string
  email: string
  workHours: number
  issues: string
  solutions: string
}
